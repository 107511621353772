<template>
  <div class="page_container">
    <div>
      <BackComponent :title="$t('VerifyYourAccount')" />
    </div>

    <div class="card_one">
      <div class="header_desc">
        <p>{{ this.$t("uploadGalleryCard") }}</p>
        <div class="step-indicator">Step {{ step }} of 2</div>
      </div>
    </div>

    <!-- Step 1 of 2 for Front Upload -->
    <div v-if="step === 1">
      <div class="upload-section">
        <div class="card-preview">
          <img
            v-if="front_document_url"
            class="passport_image"
            :src="front_document_url"
            alt="passport"
          />
          <img
            v-else
            class="passport_image"
            :src="
              currentTheme === 'light'
                ? '/img/passport-card-front.png'
                : '/img/passport-card-front-dark.png'
            "
            alt="passport"
          />
          <p class="picture-side">({{ this.$t("frontSide") }})</p>
        </div>
        <div class="upload-box_wrapper">
          <div class="upload-box" @click="handleFileUpload('front')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M21 10.19H18.11C15.74 10.19 13.81 8.26 13.81 5.89V3C13.81 2.45 13.36 2 12.81 2H8.57C5.49 2 3 4 3 7.57V16.43C3 20 5.49 22 8.57 22H16.43C19.51 22 22 20 22 16.43V11.19C22 10.64 21.55 10.19 21 10.19ZM12.03 13.53C11.88 13.68 11.69 13.75 11.5 13.75C11.31 13.75 11.12 13.68 10.97 13.53L10.25 12.81V17C10.25 17.41 9.91 17.75 9.5 17.75C9.09 17.75 8.75 17.41 8.75 17V12.81L8.03 13.53C7.74 13.82 7.26 13.82 6.97 13.53C6.68 13.24 6.68 12.76 6.97 12.47L8.97 10.47C9.04 10.41 9.11 10.36 9.19 10.32C9.21 10.31 9.24 10.3 9.26 10.29C9.32 10.27 9.38 10.26 9.45 10.25C9.48 10.25 9.5 10.25 9.53 10.25C9.61 10.25 9.69 10.27 9.77 10.3C9.78 10.3 9.78 10.3 9.79 10.3C9.87 10.33 9.95 10.39 10.01 10.45C10.02 10.46 10.03 10.46 10.03 10.47L12.03 12.47C12.32 12.76 12.32 13.24 12.03 13.53Z"
                fill="#CC1717"
              />
              <path
                d="M17.9301 8.81048C18.8801 8.82048 20.2001 8.82048 21.3301 8.82048C21.9001 8.82048 22.2001 8.15048 21.8001 7.75048C20.3601 6.30048 17.7801 3.69048 16.3001 2.21048C15.8901 1.80048 15.1801 2.08048 15.1801 2.65048V6.14048C15.1801 7.60048 16.4201 8.81048 17.9301 8.81048Z"
                fill="#CC1717"
              />
            </svg>
            <button>
              {{ this.$t("clickToUploadFront") }}
            </button>
            <p>({{ this.$t("maxFileSize") }}: 5 MB)</p>
          </div>
        </div>
      </div>

      <!-- <div class="progress_bar_con" v-if="uploadProgress !== null">
        <p>Upload Progress: {{ uploadProgress }}%</p>
        <progress class="progress_bar" :value="uploadProgress" max="100"></progress>
      </div> -->

      <div class="upload-wrapper" v-if="frontFileName">
        <div class="upload-progress">
          <div class="file-info">
            <div class="file-details">
              <span class="file-name">{{ frontFileName }}</span>
              <span class="file-size">{{ frontFileSize }} KB</span>
            </div>
            <button
              class="delete-icon"
              :disabled="uploadLoading"
              @click="deleteFile('front')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.5001 5.60742C17.4834 5.60742 17.4584 5.60742 17.4334 5.60742C13.0251 5.16575 8.62505 4.99909 4.26672 5.44075L2.56672 5.60742C2.21672 5.64075 1.90839 5.39075 1.87505 5.04075C1.84172 4.69075 2.09172 4.39075 2.43339 4.35742L4.13339 4.19075C8.56672 3.74075 13.0584 3.91575 17.5584 4.35742C17.9001 4.39075 18.1501 4.69909 18.1167 5.04075C18.0917 5.36575 17.8167 5.60742 17.5001 5.60742Z"
                  fill="var(--sub-text-color)"
                />
                <path
                  d="M7.08338 4.76602C7.05005 4.76602 7.01672 4.76602 6.97505 4.75768C6.64172 4.69935 6.40838 4.37435 6.46672 4.04102L6.65005 2.94935C6.78338 2.14935 6.96671 1.04102 8.90838 1.04102H11.0917C13.0417 1.04102 13.225 2.19102 13.35 2.95768L13.5334 4.04102C13.5917 4.38268 13.3584 4.70768 13.025 4.75768C12.6834 4.81602 12.3584 4.58268 12.3084 4.24935L12.125 3.16602C12.0084 2.44102 11.9834 2.29935 11.1 2.29935H8.91672C8.03338 2.29935 8.01672 2.41602 7.89172 3.15768L7.70005 4.24102C7.65005 4.54935 7.38338 4.76602 7.08338 4.76602Z"
                  fill="var(--sub-text-color)"
                />
                <path
                  d="M12.675 18.9577H7.325C4.41666 18.9577 4.3 17.3493 4.20833 16.0493L3.66666 7.65766C3.64166 7.316 3.90833 7.016 4.25 6.991C4.6 6.97433 4.89166 7.23266 4.91666 7.57433L5.45833 15.966C5.55 17.2327 5.58333 17.7077 7.325 17.7077H12.675C14.425 17.7077 14.4583 17.2327 14.5417 15.966L15.0833 7.57433C15.1083 7.23266 15.4083 6.97433 15.75 6.991C16.0917 7.016 16.3583 7.30766 16.3333 7.65766L15.7917 16.0493C15.7 17.3493 15.5833 18.9577 12.675 18.9577Z"
                  fill="var(--sub-text-color)"
                />
                <path
                  d="M11.3834 14.375H8.6084C8.26673 14.375 7.9834 14.0917 7.9834 13.75C7.9834 13.4083 8.26673 13.125 8.6084 13.125H11.3834C11.7251 13.125 12.0084 13.4083 12.0084 13.75C12.0084 14.0917 11.7251 14.375 11.3834 14.375Z"
                  fill="var(--sub-text-color)"
                />
                <path
                  d="M12.0834 11.041H7.91675C7.57508 11.041 7.29175 10.7577 7.29175 10.416C7.29175 10.0743 7.57508 9.79102 7.91675 9.79102H12.0834C12.4251 9.79102 12.7084 10.0743 12.7084 10.416C12.7084 10.7577 12.4251 11.041 12.0834 11.041Z"
                  fill="var(--sub-text-color)"
                />
              </svg>
            </button>
          </div>

          <div class="progress-bar_wrapper">
            <div class="progress-bar">
              <div
                class="progress"
                :style="{ width: frontUploadProgress + '%' }"
              ></div>
            </div>
            <div class="progress-percentage">{{ frontUploadProgress }}%</div>
          </div>
        </div>
      </div>

      <!-- <ChopbetButton :disabled="status && status !== -1" variant="outlined" @click="goToNextStep"
        >Next: Upload Back Side
      </ChopbetButton> -->
    </div>

    <!-- Step 2 of 2 for Back Upload -->
    <div v-if="step === 2">
      <div class="upload-section">
        <div class="card-preview">
          <img
            v-if="back_document_url"
            class="passport_image"
            :src="back_document_url"
            alt="passport"
          />
          <img
            v-else
            class="passport_image"
            :src="
              currentTheme === 'light'
                ? '/img/passport-card-back.png'
                : '/img/passport-card-back-dark.png'
            "
            alt="passport"
          />
          <p class="picture-side">({{ this.$t("backSide") }})</p>
        </div>
        <div class="upload-box_wrapper">
          <div class="upload-box" @click="handleFileUpload('back')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M21 10.19H18.11C15.74 10.19 13.81 8.26 13.81 5.89V3C13.81 2.45 13.36 2 12.81 2H8.57C5.49 2 3 4 3 7.57V16.43C3 20 5.49 22 8.57 22H16.43C19.51 22 22 20 22 16.43V11.19C22 10.64 21.55 10.19 21 10.19ZM12.03 13.53C11.88 13.68 11.69 13.75 11.5 13.75C11.31 13.75 11.12 13.68 10.97 13.53L10.25 12.81V17C10.25 17.41 9.91 17.75 9.5 17.75C9.09 17.75 8.75 17.41 8.75 17V12.81L8.03 13.53C7.74 13.82 7.26 13.82 6.97 13.53C6.68 13.24 6.68 12.76 6.97 12.47L8.97 10.47C9.04 10.41 9.11 10.36 9.19 10.32C9.21 10.31 9.24 10.3 9.26 10.29C9.32 10.27 9.38 10.26 9.45 10.25C9.48 10.25 9.5 10.25 9.53 10.25C9.61 10.25 9.69 10.27 9.77 10.3C9.78 10.3 9.78 10.3 9.79 10.3C9.87 10.33 9.95 10.39 10.01 10.45C10.02 10.46 10.03 10.46 10.03 10.47L12.03 12.47C12.32 12.76 12.32 13.24 12.03 13.53Z"
                fill="#CC1717"
              />
              <path
                d="M17.9301 8.81048C18.8801 8.82048 20.2001 8.82048 21.3301 8.82048C21.9001 8.82048 22.2001 8.15048 21.8001 7.75048C20.3601 6.30048 17.7801 3.69048 16.3001 2.21048C15.8901 1.80048 15.1801 2.08048 15.1801 2.65048V6.14048C15.1801 7.60048 16.4201 8.81048 17.9301 8.81048Z"
                fill="#CC1717"
              />
            </svg>
            <button>
              {{ this.$t("clickToUploadBack") }}
            </button>
            <p>({{ this.$t("maxFileSize") }}: 5 MB)</p>
          </div>
        </div>
      </div>

      <div class="upload-wrapper" v-if="backFileName">
        <div class="upload-progress">
          <div class="file-info">
            <div class="file-details">
              <span class="file-name">{{ backFileName }}</span>
              <span class="file-size">{{ backFileSize }} KB</span>
            </div>
            <button
              class="delete-icon"
              :disabled="uploadLoading"
              @click="deleteFile('back')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.5001 5.60742C17.4834 5.60742 17.4584 5.60742 17.4334 5.60742C13.0251 5.16575 8.62505 4.99909 4.26672 5.44075L2.56672 5.60742C2.21672 5.64075 1.90839 5.39075 1.87505 5.04075C1.84172 4.69075 2.09172 4.39075 2.43339 4.35742L4.13339 4.19075C8.56672 3.74075 13.0584 3.91575 17.5584 4.35742C17.9001 4.39075 18.1501 4.69909 18.1167 5.04075C18.0917 5.36575 17.8167 5.60742 17.5001 5.60742Z"
                  fill="var(--sub-text-color)"
                />
                <path
                  d="M7.08338 4.76602C7.05005 4.76602 7.01672 4.76602 6.97505 4.75768C6.64172 4.69935 6.40838 4.37435 6.46672 4.04102L6.65005 2.94935C6.78338 2.14935 6.96671 1.04102 8.90838 1.04102H11.0917C13.0417 1.04102 13.225 2.19102 13.35 2.95768L13.5334 4.04102C13.5917 4.38268 13.3584 4.70768 13.025 4.75768C12.6834 4.81602 12.3584 4.58268 12.3084 4.24935L12.125 3.16602C12.0084 2.44102 11.9834 2.29935 11.1 2.29935H8.91672C8.03338 2.29935 8.01672 2.41602 7.89172 3.15768L7.70005 4.24102C7.65005 4.54935 7.38338 4.76602 7.08338 4.76602Z"
                  fill="var(--sub-text-color)"
                />
                <path
                  d="M12.675 18.9577H7.325C4.41666 18.9577 4.3 17.3493 4.20833 16.0493L3.66666 7.65766C3.64166 7.316 3.90833 7.016 4.25 6.991C4.6 6.97433 4.89166 7.23266 4.91666 7.57433L5.45833 15.966C5.55 17.2327 5.58333 17.7077 7.325 17.7077H12.675C14.425 17.7077 14.4583 17.2327 14.5417 15.966L15.0833 7.57433C15.1083 7.23266 15.4083 6.97433 15.75 6.991C16.0917 7.016 16.3583 7.30766 16.3333 7.65766L15.7917 16.0493C15.7 17.3493 15.5833 18.9577 12.675 18.9577Z"
                  fill="var(--sub-text-color)"
                />
                <path
                  d="M11.3834 14.375H8.6084C8.26673 14.375 7.9834 14.0917 7.9834 13.75C7.9834 13.4083 8.26673 13.125 8.6084 13.125H11.3834C11.7251 13.125 12.0084 13.4083 12.0084 13.75C12.0084 14.0917 11.7251 14.375 11.3834 14.375Z"
                  fill="var(--sub-text-color)"
                />
                <path
                  d="M12.0834 11.041H7.91675C7.57508 11.041 7.29175 10.7577 7.29175 10.416C7.29175 10.0743 7.57508 9.79102 7.91675 9.79102H12.0834C12.4251 9.79102 12.7084 10.0743 12.7084 10.416C12.7084 10.7577 12.4251 11.041 12.0834 11.041Z"
                  fill="var(--sub-text-color)"
                />
              </svg>
            </button>
          </div>

          <div class="progress-bar_wrapper">
            <div class="progress-bar">
              <div
                class="progress"
                :style="{ width: backUploadProgress + '%' }"
              ></div>
            </div>
            <div class="progress-percentage">{{ backUploadProgress }}%</div>
          </div>
        </div>
      </div>

      <ChopbetButton
        :disabled="(status !== null && status !== -1) || !completeUpload"
        variant="variant"
        @click="submitBothSides"
        >{{ $t("submit") }}</ChopbetButton
      >
    </div>

    <div class="instructions_wrapper">
      <div class="instructions">
        <ul>
          <li>
            {{ $t("uploadInstructions.5th") }}
          </li>
          <li>{{ $t("uploadInstructions.2nd") }}</li>
          <li>
            {{ $t("uploadInstructions.3rd") }}
          </li>
          <li>
            {{ $t("uploadInstructions.4th") }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BackComponent from "../../../../components/ui/BackComponent.vue";
import ChopbetButton from "../../../../components/ui/ChopbetButton.vue";
import instance from "../../../../services/identity";

export default {
  components: {
    BackComponent,
    ChopbetButton,
  },
  data() {
    return {
      step: 1,
      frontFileName: null,
      frontFileSize: null,
      frontUploadProgress: 0,
      backFileName: null,
      backFileSize: null,
      uploadLoading: false,
      front_document_url: "",
      back_document_url: "",
      imgUrls: [],
      backUploadProgress: 0,
      phonenumber: this.getProfile()?.msisdn
        ? String(this.getProfile()?.msisdn).slice(3)
        : "",
      email: this.getProfile()?.email,
      firstName: this.getProfile()?.first_name,
      lastName: this.getProfile()?.last_name,
      dateOfBirth: this.getProfile()?.dob,
      myProfile: this.getProfile(),
      status: null,
      completeUpload: false,
      currentTheme: this.$store.state.theme,
    };
  },
  mounted() {
    this.getKycDetails();
  },
  computed: {
    documentType() {
      return this.$route.params.type;
    },
  },
  watch: {
    imgUrls(newImgUrls) {
      if (newImgUrls) {
        const validImgUrls = newImgUrls.filter(
          (url) => url && url.trim() !== ""
        );
        this.completeUpload = validImgUrls.length > 1;
      }
    },
  },
  created() {
    // Load the current step from localStorage if available
    const savedStep = localStorage.getItem("x_acc_v_step");
    if (savedStep) {
      this.step = Number(savedStep);
    }
  },
  _methods: {
    getKycDetails() {
      var vm = this;
      var path = "/kycs";

      instance
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data;
          // vm.setSuccess( msg);
          vm.firstName = msg.first_name;
          vm.lastName = msg.last_name;
          vm.email = msg.email;
          vm.dateOfBirth = msg.date_of_birth;
          vm.status = msg.status;
          vm.front_document_url = msg.document_urls[0];
          vm.back_document_url = msg.document_urls[1];
          vm.imgUrls = [vm.front_document_url, vm.back_document_url];
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              // vm.setError("Failed", err.response.data.error_message);
              return;
            }
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },
    handleFileUpload(side) {
      var p = this.getAuth();
      const MAX_FILE_SIZE_MB = 5 * 1024 * 1024;
      if (!p) {
        this.setError(this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/jpeg, image/png, image/jpg";

      input.click();
      if (side === "front") {
        input.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
            if (file.size > MAX_FILE_SIZE_MB) {
              this.setError(`${this.$t("bigFile")}`);
              return;
            }

            this.frontFileName = file.name;
            this.frontFileSize = (file.size / 1024).toFixed(2);
            this.uploadFile(file, "front");
          }
        };
      } else if (side === "back") {
        input.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
            if (file.size > MAX_FILE_SIZE_MB) {
              this.setError(`${this.$t("bigFile")}`);
              return;
            }
            this.backFileName = file.name;
            this.backFileSize = (file.size / 1024).toFixed(2);
            this.uploadFile(file, "back");
          }
        };
      }
    },

    uploadFile(file, side) {
      const formData = new FormData();
      formData.append("file", file);

      const updateImgUrls = (index, data) => {
        this.$set(this.imgUrls, index, data);
      };

      if (side === "front") {
        const xhr = new XMLHttpRequest();
        xhr.open(
          "POST",
          `${process.env.VUE_APP_IDENTITY_BASE_URL}/kycs/upload?lang=${this.$i18n.locale}`,
          true
        );
        xhr.setRequestHeader("api-key", `${this.getAuth() || ""}`);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            this.frontUploadProgress = Math.round(
              (event.loaded / event.total) * 100
            );
            this.uploadLoading = true;
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200 || xhr.status === 201) {
            const response = JSON.parse(xhr.responseText);
            this.front_document_url = response.data;
            updateImgUrls(0, response.data);
            this.frontUploadProgress = null;
            this.frontFileName = null;
            this.uploadLoading = false;
            this.step = 2;
          } else {
            const errorResponse = JSON.parse(xhr.responseText);
            this.setError(
              errorResponse.error_message ||
                `${this.$t("unknownErrorOccurred")}`
            );
            setTimeout(() => {
              this.frontFileName = null;
            }, 500);
          }
        };

        xhr.onerror = () => {
          console.error("Error during upload:", xhr.responseText);
        };

        xhr.send(formData);
      } else if (side === "back") {
        const xhr = new XMLHttpRequest();
        xhr.open(
          "POST",
          `${process.env.VUE_APP_IDENTITY_BASE_URL}/kycs/upload?lang=${this.$i18n.locale}`,
          true
        );
        xhr.setRequestHeader("api-key", `${this.getAuth() || ""}`);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            this.backUploadProgress = Math.round(
              (event.loaded / event.total) * 100
            );
            this.uploadLoading = true;
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200 || xhr.status === 201) {
            const response = JSON.parse(xhr.responseText);
            this.back_document_url = response?.data;
            updateImgUrls(1, response.data);
            this.backUploadProgress = null;
            this.backFileName = null;
            this.uploadLoading = false;
          } else {
            console.error("Upload back failed:", xhr.responseText);
            const errorResponse = JSON.parse(xhr.responseText);
            this.setError(errorResponse.error_message || "an error occured");
            setTimeout(() => {
              this.backFileName = null;
            }, 500);
          }
        };

        xhr.onerror = () => {
          console.error("Error during back page upload:", xhr.responseText);
          if (xhr.status === 0) {
            this.setError(this.$t("checkYourNetwork"));
          } else {
            try {
              const response = JSON.parse(xhr.responseText);
              this.setError(
                response.error_message || this.$t("unknownErrorOccurred")
              );
              console.log("Error Response Data:", response);
              console.log("Error Response Status:", xhr.status);
            } catch (parseError) {
              this.setError(this.$t("unknownErrorOccurred"));
              console.log("Non-JSON Error Response:", xhr.responseText);
            }
          }
        };

        xhr.send(formData);
      }
    },

    deleteFile(side) {
      if (side === "front") {
        this.frontFileName = "";
        this.frontFileSize = "";
        this.frontUploadProgress = null;
      } else if (side === "back") {
        this.backFileName = "";
        this.backFileSize = "";
        this.backUploadProgress = null;
      }
    },

    goToNextStep() {
      this.step = 2;
      localStorage.setItem("x_acc_v_step", this.step);
    },
    submitBothSides: async function () {
      var path = `${process.env.VUE_APP_IDENTITY_BASE_URL}/kycs?lang=${this.$i18n.locale}`;
      const savedPayload = localStorage.getItem("x_acc_v_payload1");
      const parsedPayload = JSON.parse(savedPayload);

      const payload = {
        first_name: parsedPayload.first_name,
        last_name: parsedPayload.last_name,
        email: parsedPayload.email,
        date_of_birth: parsedPayload.date_of_birth,
        phone_number: `${this.getProfile()?.msisdn}`,
        document_urls: [this.front_document_url, this.back_document_url],
      };
      if (payload.document_urls.length === 1) {
        payload.document_urls.push(this.back_document_url);
      }

      this.step = 1;
      localStorage.setItem("x_acc_v_step", this.step);

      try {
        await instance.post(path, JSON.stringify(payload), {
          headers: {
            "api-key": this.getAuth(),
          },
        });

        this.loading = false;
        this.showSuccess = true;
        this.$router.push("/verification-success");
        localStorage.removeItem("x_acc_v_payload1");
      } catch (err) {
        this.loading = false;

        if (err.response) {
          const errorCode = parseInt(err.response.data.error_code);
          if (errorCode === 401 || errorCode === 428) {
            this.setError("unauthorised");
            this.logout();
            return;
          } else {
            this.setError(err.response.data.error_message || "Failed");
          }
        } else if (err.request) {
          this.setError(
            `${this.$t("networkError")}`,
            `${this.$t("checkYourNetwork")}`
          );
        } else {
          console.log(JSON.stringify(err));
        }
      }
    },
  },
  get methods() {
    return this._methods;
  },
  set methods(value) {
    this._methods = value;
  },
};
</script>

<style src="./index.css" scoped></style>
